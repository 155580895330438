import React from "react";
import {QRCodeSVG} from 'qrcode.react';
import ReactDOM from 'react-dom';
const QrCodeGenerator = ({ url }) => {
  
  return (
    <div>
      <h1>QR Code </h1>
      <QRCodeSVG  value={url} size={200} />
    </div>
  );

 
};

export default QrCodeGenerator;
