import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import {  connectFunctionsEmulator } from "firebase/functions";


// Your Firebase config here
const firebaseConfig = {
  

  apiKey: "AIzaSyD3BQOYQS6mLnltpdXX0EpNH6gZVS_K7RI",
  authDomain: "dintact-linklib.firebaseapp.com",
  projectId: "dintact-linklib",
  storageBucket: "dintact-linklib.appspot.com",
  messagingSenderId: "26910921752",
  appId: "1:26910921752:web:c5f6b9040afe759ecd1f6d"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

// Connect functions to the emulator if running locally
if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}


export { functions };