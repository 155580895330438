import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import User from "./components/User";
import QrCodeGenerator from "./components/QrCodeGenerator";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<User />} />
      </Routes>
    </Router>
  );
};

export default App;


// import React, { useEffect } from "react";
// import { functions } from "./firebaseConfig";
// import { httpsCallable } from "firebase/functions";

// const App = () => {
//   useEffect(() => {
//     const helloWorld = httpsCallable(functions, "llBio/biodebug");
//     helloWorld()
//       .then(result => {
//         console.log(result.data); // "Hello from Firebase!"
//       })
//       .catch(error => {
//         console.error("Error calling function:", error);
//       });
//   }, []);

//   return <div className="App">Check the console for the Firebase function result.</div>;
// };

// export default App;


// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
